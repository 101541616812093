import React, { useState } from "react";
import { slide as Menu } from "react-burger-menu";
import { Link } from "react-router-dom";
import bgPic from "../../Data/background-stan.webp";

import SpotifySide from "./spotify";
import "../../styles/side-menu.css";

const menuStyles = {
  bmMenu: { backgroundImage: `url(${bgPic})`, backgroundSize: "contain" },
};

export default (props) => {
  const [menuOpenState, setMenuOpenState] = useState(false);
  const stateChangeHandler = (newState) => setMenuOpenState(newState.isOpen);

  return (
    <Menu
      styles={menuStyles}
      isOpen={menuOpenState}
      onStateChange={(state) => {
        stateChangeHandler(state);
      }}
    >
      <Link to="/">
        <div
          onClick={() => {
            setMenuOpenState(false);
          }}
        >
          Home
        </div>
      </Link>
      <Link to="/food">
        <div
          onClick={() => {
            setMenuOpenState(false);
          }}
        >
          Recipe Book
        </div>
      </Link>
      <Link to="/git">
        <div
          onClick={() => {
            setMenuOpenState(false);
          }}
        >
          Git
        </div>
      </Link>
      <Link to="/projects">
        <div
          onClick={() => {
            setMenuOpenState(false);
          }}
        >
          Live Sites
        </div>
      </Link>
      <SpotifySide />
    </Menu>
  );
};
