import React, { Component } from "react";
import { BrowserRouter } from "react-router-dom";
import SideMenu from "./components/Sidebar/side-menu";
import Header from "./components/header";
import chest from "./Data/zelda.ogg";
import chest2 from "./Data/zelda.mp3";
import Routes from "./routes.jsx";
import "./App.css";
import bgPic from "./Data/background-stan.webp";

class App extends Component {
  constructor(props) {
    super(props);
    console.log("Have you found it?");
    setTimeout(() => {
      console.log("Its not hidden very well.");
    }, 5000);
    this.state = {
      keys: "",
    };
    document.addEventListener("keydown", this.handleKeyPress, false);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyPress, false);
  }

  handleKeyPress = (event) => {
    this.setState((oldState) => {
      let konami = oldState.keys;
      if (konami.length > 19) {
        konami = konami.substring(0, 18) + event.keyCode;
      } else {
        konami = konami + event.keyCode;
      }
      return {
        keys: konami,
      };
    });
    //up up down down left right left right b a
    if (this.state.keys === "38384040373937396665") {
      this.refs.konamiSound.play();
    }
  };

  render() {
    return (
      <BrowserRouter>
        <div
          className="App"
          style={{
            backgroundImage: `url(${bgPic})`,
            backgroundSize: "contain",
          }}
        >
          <audio id="konamiSound" ref="konamiSound">
            <source src={chest} type="audio/ogg" />
            <source src={chest2} type="audio/mp3" />
          </audio>
          <SideMenu />
          <div>
            <Header />
            <Routes />
          </div>
        </div>
      </BrowserRouter>
    );
  }
}

export default App;
