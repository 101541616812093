import React from "react";
import { Link } from "react-router-dom";
import { FaGithub, FaLinkedin, FaTwitter, FaRegFile } from "react-icons/fa";
import ReactTooltip from "react-tooltip";
import "../App.css";
import "../styles/Content/header.css";

let Header = () => (
  <div
    className="header textNoCursor"
    style={{ display: "flex", height: "10vh" }}
  >
    <ReactTooltip
      place="bottom"
      type="light"
      className="tooltips"
      border={true}
      borderColor="black"
    />
    <div className="spaceDiv" style={{ flex: "1" }} />
    <h1 id="title">
      <Link to="/">AG-Labs </Link>
    </h1>
    <div id="headerLinks">
      <a href="https://github.com/AG-Labs" target="blank">
        <FaGithub className="headerIcon" />
      </a>
      <a href="https://www.linkedin.com/in/ajgodley/" target="blank">
        <FaLinkedin className="headerIcon" />
      </a>
      <a href="https://twitter.com/ag_labs" target="blank">
        <FaTwitter className="headerIcon" />
      </a>
    </div>
  </div>
);

export default Header;
