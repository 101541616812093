import React from "react";
import Hexagon from "react-hexagon";

let Home = () => (
  <header className="App-body">
    <div style={{ width: "80%", maxWidth: "400px", textAlign: "left" }}>
      <p>I'm Andy</p>
      <p>
        I'm a software engineer from London and on this site you'll find some of
        my projects
      </p>
    </div>
    <div className='profile'
      style={{
        maxHeight: "70%",
        maxWidth: "70%",
        height: "auto",
        width: "25%",
      }}
    >
      <Hexagon
        flatTop
        backgroundScale={0.95}
        xOffset={0}
        yOffset={30}
        backgroundImage={
          "https://pbs.twimg.com/profile_images/1252209854044934145/yjncjDEX_400x400.jpg"
        }
        //https://avatars2.githubusercontent.com/u/30706188?v=4
        style={{ stroke: "#000000" }}
      />
    </div>
  </header>
);
export default Home;
