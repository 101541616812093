import React, { Component } from "react";
import "../../styles/spotify.css";
import { MdPlayCircleOutline, MdPauseCircleOutline } from "react-icons/md";

class SpotifyIcons extends Component {
  constructor(props) {
    super(props);
    this.state = {
      spotifyData: []
    };
    this.displayIcons = this.displayIcons.bind(this);
  }

  componentDidMount() {
    const self = this;
    fetch("/.netlify/functions/spotify-lambda")
      .then(res => res.json())
      .then(
        result => {
          result.items.forEach(element => {
            element.isPlaying = false;
          });
          this.setState({ spotifyData: result.items });
        },
        error => {
          console.log("error");
          console.log("Couldn't get data from spotify");
        }
      );

    window.addEventListener(
      "play",
      function (evt) {
        if (
          window.$_currentlyPlaying &&
          window.$_currentlyPlaying != evt.target
        ) {
          self.setState(oldState => {
            let change =
              oldState.spotifyData[window.$_currentlyPlaying.id.slice(-1)];
            change.isPlaying = false;
            let newState = oldState;
            newState.spotifyData[
              window.$_currentlyPlaying.id.slice(-1)
            ] = change;

            return newState;
          });
          window.$_currentlyPlaying.pause();
        }
        window.$_currentlyPlaying = evt.target;
      },
      true
    );
  }

  displayIcons() {
    return this.state.spotifyData.map((song, index) => {
      let overlay = null;
      if (song.isPlaying) {
        overlay = <MdPauseCircleOutline size="40" color="white" />;
      } else {
        overlay = <MdPlayCircleOutline size="40" color="white" />;
      }

      return (
        <div
          key={"song" + index}
          onClick={() => {
            this.setState(oldState => {
              let change = oldState.spotifyData[index];
              change.isPlaying = !change.isPlaying;
              let newState = oldState;
              newState.spotifyData[index] = change;
              return newState;
            });
            var playTrack = document.getElementById("track" + index);
            if (playTrack.paused) {
              playTrack.play();
            } else {
              playTrack.pause();
            }
          }}
        >
          <audio
            id={"track" + index}
            onEnded={input => {
              this.setState(oldState => {
                let change = oldState.spotifyData[index];
                change.isPlaying = !change.isPlaying;

                let newState = oldState;
                newState.spotifyData[index] = change;
                return newState;
              });
            }}
          >
            <source src={song.preview_url} type="audio/mpeg" />
          </audio>
          <img src={song.album.images[2].url} alt="album art" />
          {overlay}
          <p style={{ margin: "0", fontSize: "80%" }}>{song.name}</p>
        </div>
      );
    });
  }

  render() {
    return (
      <div className="spotify">
        <div style={{ marginBottom: "10px" }}>Spotify Top Tracks</div>
        <div className="spotifyPics">{this.displayIcons()}</div>
      </div>
    );
  }
}

export default SpotifyIcons;
