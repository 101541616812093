import React, { lazy, Suspense } from "react";
import { Route, Switch } from "react-router-dom";

import Loading from "./components/loading";
import Home from "./components/Content/home";
const NotFound = lazy(() => import("./components/Content/generic-not-found"));
const Construction = lazy(() =>
  import("./components/Content/under-construction")
);
const GitList = lazy(() => import("./components/Content/git-page/git-items"));
const FoodPage = lazy(() => import("./components/Content/foodPage/food-items"));
const Projects = lazy(() => import("./components/Content/Projects/projects"));

let Routes = () => {
  return (
    <Suspense fallback={<Loading />}>
      <Switch>
        <Route exact path="/" render={() => <Home />} />
        <Route
          path="/food"
          render={(routeProps) => <FoodPage {...routeProps} />}
        />
        <Route path="/git" render={() => <GitList />} />
        <Route path="/projects" render={() => <Projects />} />
        <Route
          path="/construction"
          render={() => <Construction text="why are you looking here?" />}
        />

        <Route render={() => <NotFound />} />
      </Switch>
    </Suspense>
  );
};

export default Routes;
